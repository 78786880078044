import type { MaybeRef } from 'vue'
import type { ValidationRule } from '~/components/UiKit/base/types'
import { useAppStore } from '#imports'
import { useTranslation } from 'i18next-vue'
import { unref } from 'vue'
import { Alphabet } from '~/utils/Alphabet'

export interface FieldConfig {
    languages: string[]
    charSet: string
    lengthMin: number
    lengthMax: number
    isI18n: boolean
    useAlphabet: boolean
    isMandatory: boolean
}

// Key table.field
type Key = string
type FieldName = string

const plainIndex = new Map<Key, FieldConfig>()
const tableIndex = new Map<FieldName, Map<Key, FieldConfig>>()

export const alphabet = new Alphabet()

export function loadFieldsConfig(tables: any[]) {
    tables.forEach(row => {
        const [table, fields] = row
        let it = tableIndex.get(table)
        if (!it) {
            it = new Map<string, FieldConfig>()
            tableIndex.set(table, it)
        }
        for (let fieldConfig of fields || []) {
            const [field, languages, charSet, lengthMin, lengthMax, isI18n, useAlphabet, isMandatory] = fieldConfig
            const fc: FieldConfig = { languages, charSet, lengthMin, lengthMax, isI18n, useAlphabet, isMandatory }
            plainIndex.set(`${table}.${field}`, fc)
            it.set(field, fc)
        }
    })
}

export function fieldConfig(key: string): FieldConfig {
    const conf = plainIndex.get(key)
    if (!conf) {
        console.error(`Empty field config "${key}". Default used.`)
        return {
            charSet: '',
            isI18n: false,
            isMandatory: false,
            languages: [],
            lengthMax: 200,
            lengthMin: 2,
            useAlphabet: false,
        }
    }
    return conf
}

export function buildRules(config: FieldConfig) {
    const { t } = useTranslation()
    const { isMandatory, lengthMin, lengthMax } = config

    const rules: ValidationRule[] = []

    if (!isMandatory && !lengthMin && !lengthMax) {
        return rules
    }

    if (!isMandatory) {
        if (lengthMin) {
            rules.push(v => !v || v.length >= lengthMin || t('@minMaxNChars', { count: lengthMin, what: '$t(@minimum)' }))
        }
        if (lengthMax) {
            rules.push(v => !v || v.length <= lengthMax || t('@minMaxNChars', { count: lengthMax, what: '$t(@maximum)' }))
        }
    } else {
        if (lengthMin) {
            rules.push(v => v.length >= lengthMin || t('@minMaxNChars', { count: lengthMin, what: '$t(@minimum)' }))
        }
        if (lengthMax) {
            rules.push(v => v.length <= lengthMax || t('@minMaxNChars', { count: lengthMax, what: '$t(@maximum)' }))
        }
    }

    return rules
}

export function buildPatternReplacer(config: FieldConfig) {
    const { useAlphabet, charSet } = config
    if (!useAlphabet && !charSet) {
        return undefined
    }
    return alphabet.replacer(charSet, useAlphabet)
}

export function iText(item: MaybeRef<Record<string, any>> | undefined | null, fields: { name: string, classes?: string, placeholder?: string }[], table: string) {
    // Выбранный язык данных
    const needLang = useAppStore().langData
    return fields.map(field => {
        const cfg = fieldConfig(`${table}.${field.name}`)
        // на случай указания item = item?.manufacturer
        const val = item ? unref(item)[field.name] : {}
        let text = field.placeholder ?? '—'
        let classes = field.classes ?? ''
        if (val[needLang]) {
            // Выбранный язык данных
            text = val[needLang]
        } else {
            // Альтернативное отображение, порядок: 2. обязательные языки поля, 3. имеющиеся данные
            const langs = new Set([...(cfg?.languages ?? []), ...Object.keys(val)])
            for (let lang of langs) {
                if (val[lang]) {
                    text = `${val[lang]}`
                    // добавляем класс с кодом языка
                    classes += ` alien-lang--${lang}`
                    break
                }
            }
        }
        return {
            name: text,
            // если значения нет - гасим классы (если указан непустой placeholder классы оставляем)
            classes: text ? classes.trim() : '',
        }
    })
}

export type TCheckUniq = 'off' | 'space_free' | 'symbol_free' | 'case_free' | 'order_free'

export const checkUniqOptions = [
    { id: 'off', i18nName: '@searchPersistencyOffName', i18nDescription: '@searchPersistencyOff' },
    { id: 'space_free', i18nName: '@searchPersistencySpaceFreeName', i18nDescription: '@searchPersistencySpaceFree' },
    { id: 'symbol_free', i18nName: '@searchPersistencySymbolFreeName', i18nDescription: '@searchPersistencySymbolFree' },
    { id: 'case_free', i18nName: '@searchPersistencyCaseFreeName', i18nDescription: '@searchPersistencyCaseFree' },
    { id: 'order_free', i18nName: '@searchPersistencyOrderFreeName', i18nDescription: '@searchPersistencyOrderFree' },
]
const checkUniqMap = new Map(checkUniqOptions.map((i, offset) => [i.id, offset]))
export function checkUniqById(id: string) {
    const offset = checkUniqMap.get(id)
    if (offset === undefined || !checkUniqOptions[offset]) {
        throw new Error(`Unknown checkUniq id: ${id}`)
    }
    return checkUniqOptions[offset]
}
