import type { FetchOpts } from '~/utils/fetcher'
import { fetcher } from '~/utils/fetcher'

export function useFetchCall<Result, Body>(
    endpoint = '/',
    body: Body,
    opts: FetchOpts & {
        background?: boolean
        onData?: (d: Result) => void
    } = {},
): Promise<Result> {
    const {
        onData = () => {},
    } = opts
    return new Promise<Result>((resolve, reject) => {
        const { exec } = fetcher({
            background: true,
            ...opts,
            makeUrl: () => endpoint,
            makeBody: () => body ?? {},
            onResponse: json => {
                onData(json)
                resolve(json)
            },
            onError: err => reject(err),
        })
        exec()
    })
}
