import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useFetchCall } from '~/composables/useFetchCall'
import { Alphabet } from '~/utils/Alphabet'
import { alphabet } from '~/utils/fieldsConfig'

interface ConfigStore {
    alphabet: string
}

const updating = ref(false)
export const useConfigStore = defineStore('config', {
    state: (): ConfigStore => ({
        alphabet: '-a-Zа-ЯёЁ0-9',
    }),
    actions: {
        boot(config: ConfigStore) {
            // генератор регулярок с алфавитом
            alphabet.setAlphabet(config.alphabet)
            this.$patch(config)
        },
        updating() {
            return updating.value
        },
        async setValue(key: keyof ConfigStore, value: string) {
            if (key === 'alphabet') {
                try {
                    // validate
                    new Alphabet().setAlphabet(value).replacer('', true)
                } catch (e) {
                    throw new Error('Invalid alphabet')
                }
            }

            updating.value = true
            this.$state = await useFetchCall('/configUpdate', { [key]: value })
            updating.value = false
        },
    },
})
