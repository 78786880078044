<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
    <RModal />
    <RDialog />
    <RToast />
    <AppLoading />
</template>

<script setup lang="ts">
import { clearError, showError } from '#app'
import { onMounted, onUnmounted } from 'vue'
import RDialog from '~/components/UiKit/modal/RDialog.vue'
import RModal from '~/components/UiKit/modal/RModal.vue'
import RToast from '~/components/UiKit/modal/RToast.vue'

onMounted(() => {
    document.addEventListener('keydown', softUpdate)
})
onUnmounted(() => {
    document.removeEventListener('keydown', softUpdate)
})

function softUpdate(e) {
    if (e instanceof KeyboardEvent && e.code === 'F5' && e.altKey) {
        e.preventDefault()
        showError(new Error('SoftReload')) // fake error
        setTimeout(clearError, 10)
    }
}
</script>
