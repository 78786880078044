import type { Ref } from 'vue'
import { navigateTo } from '#app'
import { ref, watch } from 'vue'

type Awaitable<T> = T | Promise<T>

// todo idea: брать из заголовков requestId для отображения в окне ошибки или для передачи в Sentry

const { fetch } = globalThis
const baseUrl = new URL('/api/ui', window.location.origin)

export interface FetchOpts {
    // Сформировать путь запроса
    makeUrl?: () => string
    // Сформировать body
    makeBody?: () => Record<string, any> | FormData
    onResponse?: (json: any) => Awaitable<void>
    onAbort?: () => void
    onError?: (err: Error) => Awaitable<void>
    waiting?: Ref<boolean>
    refAborted?: Ref<boolean>
    refError?: Ref<string>
    // фоновый запрос без блокировки UI
    background?: boolean
}

// !i! не использовать глобально (entityTotal не должен показывать прогресс)
export const fetchTime = ref(0)
export const fetchAbort = ref(0)

let timer: Timer
let fetchStarted = 0

export function fetcher<R>(
    {
        // call pending
        waiting = ref(true),
        refAborted = ref(false),
        refError = ref(''),
        makeUrl = () => '',
        makeBody = () => ({}),
        onAbort = () => {},
        onError = () => {},
        onResponse = (r: R) => {},
        background = false,
    }: FetchOpts,
) {
    let ac: AbortController

    const abort = () => {
        if (ac && waiting.value) {
            ac.abort()
        }
        ac = new AbortController()
        ac.signal.onabort = () => {
            refAborted.value = true
            onAbort()
        }
    }

    const exec = () => {
        abort()
        waiting.value = true
        const endpoint = makeUrl()
        const url = baseUrl + endpoint
        const body = makeBody()
        const headers: Record<string, string> = {
            'user-agent': 'plm-ui/v0.0.0',
        }
        if (!(body instanceof FormData)) {
            headers['content-type'] = 'application/json'
        }
        const init: RequestInit = {
            method: 'POST',
            headers,
            credentials: 'same-origin',
            signal: ac.signal,
            body: body instanceof FormData ? body : JSON.stringify(body),
        }

        let retryLeft = 3
        fetch(url, init)
            .then(async res => {
                if (res.status === 503) {
                    // [i] Nuxt частенько выбрасыват 503 ошибку при проксировании запроса к API
                    if (retryLeft) {
                        retryLeft--
                        return fetch(url, init)
                    }
                }

                if (res.headers.get('Content-Type')?.split(';')[0] !== 'application/json') {
                    throw new Error('Invalid response content type')
                }
                let json = await res.json()
                if (!res.ok) {
                    if (res.status === 401) {
                        sessionStorage.setItem('returnToAfterAuth', new URL(location.href).pathname)
                        await navigateTo(json.redirectUrl, { external: true })
                    }
                    throw Object.assign(new Error('FetcherError'), { endpoint, statusCode: res.status, ...json })
                }
                await onResponse(json)
            })
            .catch(async err => {
                if (err.name === 'AbortError') {
                    return
                }
                refError.value = err.message
                await onError(err)
            })
            .finally(() => {
                waiting.value = false
            })
    }

    if (!background) {
        watch(waiting, (to, from) => {
            if (from) {
                // stop
                clearInterval(timer)
                fetchTime.value = 0
            }
            if (to && !fetchTime.value) {
                // start
                fetchStarted = Date.now()
                fetchTime.value = 1 // для показа прогресса
                timer = setInterval(() => {
                    fetchTime.value = Date.now() - fetchStarted
                }, 500)
            }
        }, {
            immediate: true,
        })
        watch(fetchAbort, () => {
            abort()
        })
    }

    return {
        exec,
        abort,
        waiting,
        // @deprecated use waiting
        pending: waiting,
        aborted: refAborted,
        error: refError,
    }
}
