<template>
    <TransitionGroup name="r-dialog-group">
        <div
            v-if="items.length"
            class="r-dialog-container flex justify-center items-end"
            @mousedown="onMouseDown($event)"
            @click="wrapperClick($event)"
        >
            <RDialogItem
                v-for="(dialog, index) in items"
                :key="dialog.id"
                :class="getClasses(index)"
                :dialog="dialog"
                :close="close"
            />
        </div>
    </TransitionGroup>
</template>

<script lang="ts">
import type { IDialogOpts } from '~/components/UiKit'
import { domParents } from '~/utils/helper'
import { usePopup } from './composables/usePopup'
import RDialogItem from './RDialogItem.vue'

const name = 'dialog'
const { items, open, close, getClasses, onKeydown } = usePopup(name)

let lastMouseDownElement: HTMLElement

export function _useDialog() {
    return {
        open: (opts: IDialogOpts) => open(opts) as (Promise<any> | undefined),
        close,
    }
}

export default {
    components: { RDialogItem },
    setup() {
        return {
            items,
            close,
            getClasses,
        }
    },
    data() {
        return {
            durationTime: 0,
        }
    },

    mounted() {
        document.addEventListener('keydown', onKeydown)
    },

    unmounted() {
        document.removeEventListener('keydown', onKeydown)
    },

    methods: {
        onMouseDown(event: MouseEvent) {
            if (!event.target) {
                return
            }
            lastMouseDownElement = event.target as HTMLElement
        },
        wrapperClick(event: MouseEvent) {
            if (!items.value.length) {
                return
            }
            let target = event.target as HTMLElement

            if (!target) {
                return
            }
            const cls = `r-dialog`
            // chrome fix
            if (
                lastMouseDownElement
                && lastMouseDownElement.parentNode
                && domParents(lastMouseDownElement).some(el => el.classList.contains(cls))
            ) {
                return
            }
            if (target.classList.contains(cls) || domParents(target).some(p => p.classList.contains(cls))) {
                return
            }

            const path = event.composedPath ? event.composedPath() : null
            if (path?.some(n => (n as HTMLElement)?.classList?.contains(cls))) {
                return
            }

            const lastDialog: IDialogOpts | undefined = items.value[items.value.length - 1]
            if (!lastDialog?.id || lastDialog?.closeKeyDelay !== undefined) {
                return
            }
            close({ id: lastDialog.id, ref: 'overflow' })
        },
    },
}
</script>

<style>
.r-dialog-group-enter-active,
.r-dialog-group-leave-active {
    transition: all 0.4s ease;
}

.r-dialog-group-enter-from,
.r-dialog-group-leave-to {
    opacity: 0;
    transform: translateY(50px);
}

.r-dialog-container {
    position: fixed;
    z-index: 150;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.r-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 480px;
    max-width: 600px;
    height: fit-content;
    max-height: 60%;
    min-height: 226px;
    box-sizing: border-box;
    border-radius: 6px 6px 0 0;
    padding: 36px 0;
    position: relative;
    z-index: 10;
    background-color: var(--blue-g);
    box-shadow: 0 0 5px 0 rgba(200, 200, 200, 0.5);

    .r-dialog-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 18px 12px;
        .r-btn-wait-block {
            top: 2px;
            left: 2px;
            width: 32px;
            height: 32px;
            &:before {
                scale: 0.6;
            }
        }
    }

    .r-dialog-type-icon {
        margin-bottom: 22px;
    }

    .r-dialog-item-text-wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .r-dialog-header {
        font-size: var(--font-size-header-small);
        margin: 0 0 1rem;
        color: var(--blue-b);
    }

    .r-dialog-content {
        position: relative;
        padding: 1em 3.5em;
        font-size: var(--font-size-enlarged);
        margin-top: 16px;
        width: 100%;
        background-color: var(--blue-b);
        color: #fff;
    }

    &.success,
    &.green {
        background-color: var(--green-g);

        .r-dialog-header,
        .r-dialog-type-icon {
            color: var(--green-a);
        }

        .r-dialog-content {
            background-color: var(--green-a);
        }
    }

    &.warning,
    &.orange,
    &.yellow {
        background-color: var(--orange-g);

        .r-dialog-header,
        .r-dialog-type-icon {
            color: var(--orange);
        }

        .r-dialog-content {
            background-color: var(--orange);
        }
    }

    &.error,
    &.red {
        background-color: var(--red-g);

        .r-dialog-header,
        .r-dialog-type-icon {
            color: var(--red-a);
        }

        .r-dialog-content {
            background-color: var(--red-a);
        }
    }

    &.info {
        .r-dialog-header,
        .r-dialog-type-icon {
            color: var(--blue-a);
        }
    }

    .r-dialog-autoclose-timer {
        display: flex;
        margin-top: auto;
        padding: 30px 0 0;
    }

    .r-dialog-help {
        position: absolute;
        right: 25px;
        top: 50%;
        margin-top: -10px;
    }
}
</style>
