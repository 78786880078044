<template>
    <span ref="icon" class="r-icon" :style="styles" :class="classes"><slot /></span>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

/** принудительные цвета WEB/HEX или var (через пробел) */
type Color = string
/**
 * Shell - добавление корпуса для иконки (border + padding + background)
 * body - размер корпуса зависит от исходного размера svg
 * tiny - размер корпуса 20x20 px
 * small - размер корпуса 24x24 px
 * normal - размер корпуса 28x28 px
 * big - размер корпуса 32x32 px
 * ---------
 * при использовании параметра shell, первые два цвета заданные в параметре color резервируются под цвет
 * border-color и background-color, соответственно. Если директивный цвет через color не задается, то для
 * border-color: currentColor (возьмется родительский color), а для background-color: transparent
 */
type Shell = 'body' | 'tiny' | 'small' | 'normal' | 'big'
/** коэффициент изменения размера */
type Ratio = number
/** конкретная ширина в px (макс) */
type Width = number | string
/** конкретная высота в px (макс) */
type Height = number | string

export default defineComponent({
    name: 'RIcon',
    props: {
        name: { type: String as PropType<AppIcon>, required: true },
        color: { type: String as PropType<Color>, default: '' },
        shell: { type: String as PropType<Shell>, default: '' },
        ratio: { type: Number as PropType<Ratio>, default: 1 },
        width: { type: [Number, String] as PropType<Width>, default: 0 },
        height: { type: [Number, String] as PropType<Height>, default: 0 },
    },
    emits: ['loadError'],
    data() {
        return {
            svg: '' as string,
            styles: '',
            classes: '',
        }
    },
    watch: {
        name() {
            this.loadSVG().then() // then skip
        },
        ratio() {
            this.updateIcon()
        },
        width() {
            this.updateIcon()
        },
        height() {
            this.updateIcon()
        },
        color() {
            this.updateIcon()
        },
        shell() {
            this.updateIcon()
        },
    },
    mounted() {
        this.loadSVG()
    },
    methods: {
        updateIcon() {
            let colors = this.getColors()
            let styles = []
            let classes = []

            if (this.shell) {
                classes.push('icon-shell')
                // отбираем цвет, если он задан принудительно
                styles.push(`border-color: ${colors.shift() || 'currentColor'};`)

                // цвет фона (по умолчанию currentColor)
                if (colors.length) {
                    // отбираем цвет, если он задан принудительно
                    styles.push(`background-color: ${colors.shift()};`)
                }
            }

            // может упростить this.shell && classes.push(`icon-${this.shell}`) ??
            switch (this.shell) {
                case 'tiny':
                    classes.push('icon-tiny')
                    break
                case 'small':
                    classes.push('icon-small')
                    break
                case 'normal':
                    classes.push('icon-normal')
                    break
                case 'big':
                    classes.push('icon-big')
                    break
            }

            this.styles = styles.join(' ')
            this.classes = classes.join(' ')

            if (!this.$refs.icon || !this.svg) {
                return
            }
            let tmpSvg = this.svg
            let size = this.getSize()

            if (size.width || size.height) {
                let width = ''
                let height = ''
                if (size.width) {
                    width = `width="${size.width}px"`
                }
                if (size.height) {
                    height = `height="${size.height}px"`
                }
                tmpSvg = tmpSvg.replace(
                    // eslint-disable-next-line regexp/no-super-linear-backtracking
                    /<svg[^>]+(viewBox=".+")[^>]*>/, // захватываем весь открывающий тег <svg ...> и аргумент viewBox="..."
                    `<svg xmlns="http://www.w3.org/2000/svg" ${width} ${height} $1>`, // меняем на свой
                )
            }

            if (colors.length) {
                colors.forEach(color => {
                    tmpSvg = tmpSvg.replace(
                        /currentColor/, //
                        `${color}`, // меняем цвет
                    )
                })
            }

            (this.$refs.icon as HTMLElement).innerHTML = tmpSvg
        },
        getSize() {
            let { width, height, ratio } = this
            if (!width && !height && ratio > 1) {
                let match = this.svg.match(/viewBox="\d+\s\d+\s([.0-9]+)\s([.0-9]+)"/)
                if (match) {
                    ;[, width, height] = match.map(Number)
                    if (width && height) {
                        width = Math.round(width * ratio)
                        height = Math.round(height * ratio)
                    }
                }
            }
            return { width, height }
        },
        getColors() {
            return this.color.split(' ').filter(i => i.trim().length)
        },
        async loadSVG() {
            if (!this.name) {
                this.updateIcon()
                return
            }
            try {
                // noinspection TypeScriptCheckImport
                let { default: svg } = await import(`../../assets/svg/${this.name}.svg?raw`)

                this.svg = svg
                this.updateIcon()
            } catch (e) {
                this.$emit('loadError')
                // тут можно вставить заглушку (или нет...)
                console.error(e)
            }
        },
    },
})
</script>
