type Symbols = number[]
type Ranges = [number, number][]

export class Alphabet {
    private alphabetRanges: Ranges = []
    private alphabetSymbols: Symbols = []

    setAlphabet(chars: string) {
        // example -A-Za-zА-Яа-яЁёІўі\u3000\u3400-\u4DBF\u4E00-\u9FFF
        const { ranges, symbols } = this.parse(chars)
        this.alphabetRanges = ranges
        this.alphabetSymbols = symbols

        return this
    }

    private static uniHex(code: number) {
        return `\\u${code.toString(16).toUpperCase().padStart(4, '0')}`
    }

    // Парсинг в диапазоны символов и набор символов в charCode формате
    private parse(chars: string): { ranges: Ranges, symbols: Symbols } {
        // convert uni code to symbols
        chars = chars.replaceAll(/\\\\U\{([0-9A-F]{4})\}/gu, (s, code) => String.fromCharCode(Number(`0x${code}`)))

        const ranges: Ranges = []
        // parse ranges
        chars = chars.replaceAll(/(.)\.\.\.(.)/gu, (s, start, end) => {
            ranges.push([String(start).charCodeAt(0), String(end).charCodeAt(0)])
            return ''
        })

        const symbols: Symbols = []
        chars = chars.replaceAll(/(.)/gu, s => {
            symbols.push(String(s).charCodeAt(0))
            return ''
        })

        if (chars) {
            console.warn('Alphabet parse excess chars', chars)
        }

        return { ranges, symbols }
    }

    private makeRegExp(ranges: Ranges, symbols: Symbols, useAlphabet: boolean) {
        let re = ''

        ;[
            ...(useAlphabet ? this.alphabetRanges : []),
            ...ranges,
        ]
            // порядок внутри диапазона
            .map(([a, b]) => a < b ? [a, b] : [b, a])
            // сортировка диапазонов
            .sort((a, b) => a[0] - b[0])
            // преобразование в последовательность
            .forEach(([startCode, finishCode]) => {
                re += `${Alphabet.uniHex(startCode)}-${Alphabet.uniHex(finishCode)}`
            })

        ;[...new Set([
            ...(useAlphabet ? this.alphabetSymbols : []),
            ...symbols,
        ])]
            // порядок символов
            .sort((a, b) => a - b)
            .forEach(code => {
                re += Alphabet.uniHex(code)
            })

        // test regexp
        return new RegExp(`[^${re}]`, 'gi')
    }

    replacer(chars: string, useAlphabet: boolean) {
        const { ranges, symbols } = this.parse(chars)
        return this.makeRegExp(ranges, symbols, useAlphabet)
    }
}
