<template>
    <div class="lang-icon">
        <span :title="uiTitle">{{ uiLangCode.toUpperCase() }}</span>
        <template v-if="uiLangCode !== dataLangCode">
            <div class="lang-icon--line" />
            <span :title="dataTitle">{{ dataLangCode.toUpperCase() }}</span>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useAppStore } from '#imports'
import { useTranslation } from 'i18next-vue'
import { computed } from 'vue'
import { systemLanguageByCode } from '~/utils/languages'

const { t } = useTranslation()
const appStore = useAppStore()
const uiLangCode = computed(() => appStore.langUI)
const dataLangCode = computed(() => appStore.langData)
const uiTitle = computed(() => `${t('@interface')}: ${systemLanguageByCode(uiLangCode.value).nameEn} (${systemLanguageByCode(uiLangCode.value).name})`)
const dataTitle = computed(() => `${t('@data')}: ${systemLanguageByCode(dataLangCode.value).nameEn} (${systemLanguageByCode(dataLangCode.value).name})`)
</script>

<style>
.lang-icon {

  display: flex;
  flex-direction: column;

  span {
    font-family: var(--font-family-mono) monospace;
    font-size: var(--font-size-enlarged);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
  }

}
.lang-icon--line {
  width: 30px;
  display: block;
  height: 2px;
  flex-grow: 0;
  background-color: currentColor;
}
</style>
