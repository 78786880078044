import { DuplicationErrorView, IdInUseErrorView } from '#components'
import { useAppError } from '#imports'
import { t } from 'i18next'
import { defineNuxtPlugin } from 'nuxt/app'
import { useDialog } from '~/components/UiKit'

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.hook('app:manifest:update', p => {
        // eslint-disable-next-line no-console
        console.log('app:manifest:update', p)
    })

    nuxtApp.hook('app:chunkError', p => {
        // Ошибка загрузки чанка - скорее всего приложение устарело
        // eslint-disable-next-line no-console
        console.log('app:chunkError', p)
        // alert('Application updated. The page will be reloaded.')
        // location.reload()
    })

    // nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    //     handler(error)
    // }

    nuxtApp.hook('app:error', error => {
        // Called when a fatal error occurs
        handler(error)
    })

    nuxtApp.hook('vue:error', (error, instance, info) => {
        // Called when a vue error propagates to the root component
        // https://vuejs.org/api/composition-api-lifecycle.html#onerrorcaptured
        handler(error)
    })

    function handler(err: any) {
        if (err.handled) {
            return
        }
        err.handled = true

        // Sentry.captureException(err)
        if (err.name === 'DuplicationError') {
            useDialog().open({
                title: t('@rejected'),
                component: DuplicationErrorView,
                props: err.data,
                duration: 120_000,
                type: 'error',
            })
            return
        }

        if (err.name === 'IdInUseError') {
            useDialog().open({
                title: t('@rejected'),
                component: IdInUseErrorView,
                props: err.data,
                duration: 120_000,
                type: 'error',
            })
            return
        }

        const errorText = err.message
        if (errorText) {
            useAppError().show(errorText, t('@error'))
            console.error(err)
        }
    }
})
