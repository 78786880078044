import type { ExponentialView, FractionSeparator, SegmentsSeparator } from '~/components/ValueViewFormatter/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useFetchCall } from '~/composables/useFetchCall'

export interface UserSettings {
    langUi: string
    langData: string
    segSep: SegmentsSeparator
    fracSep: FractionSeparator
    mitFlag: boolean
    expView: ExponentialView
}

export interface UserStore {
    sid: number
    userId: string
    email: string
    name: string

    settings: UserSettings

    abilities: number[]
}

const updating = ref(false)

export const useUserStore = defineStore('user', {
    state: (): UserStore => ({
        sid: 0,
        userId: '',
        email: '',
        name: '',

        settings: {
            langUi: '',
            langData: '',
            segSep: 'unset',
            fracSep: 'comma',
            mitFlag: false,
            expView: 'regular',
        },

        abilities: [],
    }),
    getters: {
        updating() {
            return updating.value
        },
    },
    actions: {
        async setSettings(settings: UserSettings) {
            updating.value = true
            this.$state.settings = await useFetchCall('/userSettingsUpdate', settings) as UserSettings
            updating.value = false
        },
    },
})
