// import { useAppStore, useUserStore } from '#imports'
// import * as Sentry from '@sentry/nuxt'
// import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'
//
import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        //         const vueApp = nuxtApp.vueApp
        //         const config = useRuntimeConfig()
        //
        //         Sentry.init({
        //             app: vueApp,
        //             // todo provide at build time
        //             dsn: 'https://f57bf3845e6aeba810c187ec077cabfe@sentry.referus.ru/3',
        //             enabled: config.public.IS_DEV !== 'Y',
        //             environment: new URL(location.href).hostname,
        //             // logErrors: false,
        //             autoSessionTracking: false,
        //             normalizeDepth: 5,
        //             maxValueLength: 160,
        //             ignoreErrors: ['AbortError', 'UnauthorizedError'],
        //             beforeSend: (ev, hint) => {
        //                 ev.release = useAppStore().frontVersion
        //                 ev.user = { id: useUserStore().sid }
        //
        //                 if (config.public.IS_DEV === 'Y') {
        //                     // eslint-disable-next-line no-console
        //                     console.debug('Error->Sentry', ev)
        //                     // don't send
        //                     return null
        //                 }
        //                 return ev
        //             },
        //         })
        //
        return {
            provide: {
                //                 sentry: Sentry,
            },
        }
    },
})
